import { PageProps } from 'gatsby';

import { BasicPage } from '@components';

const EvChargerLandingPage = (props: PageProps) => {
  return (
    <BasicPage pageName="ev-charger" location={props.location}>
      <h1>EV Charger</h1>
    </BasicPage>
  );
};

export default EvChargerLandingPage;

import { useEffect } from 'react';
import { useDecision } from '@optimizely/react-sdk';
import { navigate } from 'gatsby';
import { useAtomValue } from 'jotai';

import { OptimizelyFeatureFlag } from '@entities/optimizely';
import { selectedCoverTypeAtom } from '@src/store/store';
import { CoverType } from '@entities/enums';
const useEvCharger = () => {
  const selectedCoverType = useAtomValue(selectedCoverTypeAtom);
  const [{ enabled: evChargerEnabled }] = useDecision(OptimizelyFeatureFlag.EV_CHARGER);
  useEffect(() => {
    if (!evChargerEnabled && window.location.pathname.includes('charger-cover')) {
      navigate('/');
    }
  }, [evChargerEnabled]);

  return {
    evChargerEnabled,
    evChargerSelected: selectedCoverType === CoverType.EV_CHARGER_COVER,
  };
};

export default useEvCharger;

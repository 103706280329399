import { PageProps } from 'gatsby';

import { BasicPage } from '@components';

const EvChargerPersonalDetailsPage = (props: PageProps) => {
  return (
    <BasicPage pageName="ev-charger" location={props.location}>
      <h1>Ev Charger Personal Details</h1>
    </BasicPage>
  );
};

export default EvChargerPersonalDetailsPage;

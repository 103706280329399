import { PageProps } from 'gatsby';

import useEvCharger from '@hooks/useEvCharger';
import EvChargerLandingPage from '@src/modules/EvCharger/EvChargerLandingPage';
const EvChargerLanding = (props: PageProps) => {
  useEvCharger();
  return <EvChargerLandingPage {...props} />;
};

export default EvChargerLanding;

import { TextLink } from '@ovotech/element';
import React from 'react';

import { Footnote, FootnoteReference } from '@components/Footer/Footnotes';
import { RewardOfferTermsAndConditions } from '@components/links/RewardOfferTermsAndConditions';
import { OLDER_BOILER_PREMIUM_AMOUNT } from '@fixtures/boilerBrands';
import blackFridayComparisonTable from '@resources/black-friday-comparison-table.pdf';
import { formatDate } from '@utils/DateFormat';
export const buildGenericOfferFootnote = ({
  endDate,
  id,
}: {
  endDate: Date;
  id: string;
}): Footnote => {
  return {
    content: (
      <>
        No claims can be made in the first 30 days. Offer not available on all products or for
        existing CORGI HomePlan and OVO boiler cover customers. To be eligible for this offer you
        must meet the boiler cover eligibility criteria set out during sign up. Offer expires{' '}
        {formatDate(endDate)}.
      </>
    ),
    id,
  };
};
export const blackFridayFootnotes: Record<string, Pick<FootnoteReference, 'content'>> = {
  freeForSixMonths: {
    content: (
      <>
        No claims can be made in first 30 days. Conditions, eligibility criteria, exclusions and
        cancellation charges apply. For boilers aged 7 years and older no additional premium will be
        charged for the first 6 months increasing to £2 for the remaining 6 months. Offer available
        to new customers only, and not available for existing SSE Home Services, CORGI HomePlan, OVO
        HomePlan and OVO Energy Boiler Cover customers. Offer expires 30/11/2023.
      </>
    ),
  },
  freeForSixMonthsMaxSaving: {
    content: (
      <>
        The maximum potential saving is based on each plan with no excess at full price for 12
        months, compared to paying for 6 months.
      </>
    ),
  },
  freeForSixMonthsHomeRecover: {
    content: (
      <>
        After the initial repair, no claims can be made in first 30 days. Conditions, eligibility
        criteria, exclusions and cancellation charges apply. For boilers aged 7 years and older a £2
        a month premium will be added after the initial 6 free months. Offer available to new
        customers only, and not available for existing CORGI HomePlan, OVO HomePlan and OVO Energy
        Boiler Cover customers. Offer expires 30/11/2023.
      </>
    ),
  },
  freeForSixMonthsMaxSavingHomeRecover: {
    content: (
      <>
        The maximum potential saving of £192 above is based on the Complete plan, with no excess, at
        £32 a month for 12 months, compared to paying for 6 months. Offer expires 30/11/2023.
      </>
    ),
  },
};

export const freeForFourMonthsFootnotes: Record<string, Pick<FootnoteReference, 'content'>> = {
  freeForFourMonths: {
    content: (
      <>
        No claims can be made in first 30 days. Conditions, eligibility criteria, exclusions and
        cancellation charges apply. For boilers aged 7 years and older a £2 a month premium will be
        added after the initial 4 free months. Offer available to new customers only, and not
        available for existing CORGI HomePlan, OVO HomePlan and OVO Energy Boiler Cover customers.
        Offer expires 12/12/2023.
      </>
    ),
  },
  freeForFourMonthsMaxSaving: {
    content: (
      <>
        The maximum potential saving is based on each plan with no excess at full price for 12
        months, compared to paying for 8 months.
      </>
    ),
  },
  freeForFourMonthsHomeRecover: {
    content: (
      <>
        No claims can be made in first 30 days. Conditions, eligibility criteria, exclusions and
        cancellation charges apply. For boilers aged 7 years and older a £2 a month premium will be
        added after the initial 4 free months. Offer available to new customers only, and not
        available for existing CORGI HomePlan, OVO HomePlan and OVO Energy Boiler Cover customers.
        The maximum potential saving of £128 above is based on the Complete plan, with no excess, at
        £32 a month for 12 months, compared to paying for 8 months. Offer expires 12 December 2023.
      </>
    ),
  },
};
export const freeForSixMonthsFootnotes: Record<string, Pick<FootnoteReference, 'content'>> = {
  freeForSixMonthsClaimsTerms: {
    content: (
      <>
        No claims can be made in first 30 days. Conditions, eligibility criteria, exclusions and
        cancellation charges apply. For boilers aged 7 years and older no additional premium will be
        charged for the first 6 months increasing to £2 for the remaining 6 months. Offer available
        to new customers only, and not available for existing SSE Home Services, CORGI HomePlan, OVO
        HomePlan and OVO Energy Boiler Cover customers. Offer expires 30/11/2023.
      </>
    ),
  },
  freeForFourMonthsMaxSaving: {
    content: (
      <>
        The maximum potential saving is based on each plan with no excess at full price for 12
        months, compared to paying for 6 months.
      </>
    ),
  },
  freeForFourMonthsHomeRecover: {
    content: (
      <>
        After the initial repair, no claims can be made in first 30 days. Conditions, eligibility
        criteria, exclusions and cancellation charges apply. For boilers aged 7 years and older a £2
        a month premium will be added after the initial 6 free months. Offer available to new
        customers only, and not available for existing CORGI HomePlan, OVO HomePlan and OVO Energy
        Boiler Cover customers. Offer expires 30/11/2023. The maximum potential saving is based on
        each plan with no excess at full price for 12 months, compared to paying for 6 months.
      </>
    ),
  },
};

export const energyCreditsFootnotes: Record<string, Pick<FootnoteReference, 'content'>> = {
  energyCredits: {
    content: (
      <>
        To be eligible for the energy credit you must meet the eligibility criteria set out in our{' '}
        <RewardOfferTermsAndConditions />.
      </>
    ),
  },
};

export const defaultFootnotes: Record<string, Pick<FootnoteReference, 'content'>> = {
  noClaims: {
    content: (
      <>
        No claims can be made in the first 30 days. Conditions, eligibility criteria, exclusions and
        cancellations charges apply. For boilers aged 7 years and over up to £2 a month additional
        premium will be charged.
      </>
    ),
  },
  completePolicyExcess: {
    content: (
      <>
        For the Complete policy only - If you choose not to pay a £60 excess per claim, a £50 excess
        still applies to external drains and taps and toilets claims.
      </>
    ),
  },
  policyExcess: {
    content: (
      <>
        If you choose not to pay a £60 excess per claim, a £50 excess still applies to external
        drains and taps and toilets claims.
      </>
    ),
  },
};

export const q4BoilerOfferFootnotes: Record<string, Pick<FootnoteReference, 'content'>> = {
  q4BoilerOffer: {
    content: (
      <>
        No claims can be made in first 30 days. Conditions, eligibility criteria, exclusions and
        cancellation charges apply. For boilers aged 7 years and older a £2 a month premium will be
        added after the initial 4 free months. Offer available to new customers only, and not
        available for existing CORGI HomePlan, OVO HomePlan and OVO Energy Boiler Cover customers.
        The maximum potential saving of £128 above is based on the Complete plan, with no excess, at
        £32 a month for 12 months, compared to paying for 8 months. Offer expires 12 December 2023.
      </>
    ),
  },
};

export const oceanPromoFootnotes: Record<'oceanPromo', Pick<FootnoteReference, 'content'>> = {
  oceanPromo: {
    content: (
      <>
        Path to Zero Offer: to be eligible for our £1 a month offer, you must be an existing OVO
        Energy customer and meet the eligibility criteria set out in our
        <TextLink
          aria-Label={`terms and conditions`}
          href={
            'https://homeservices.ovoenergy.com/static/terms-abs-4d98f44d05fc44e394c55a5a2aa4dcfd.pdf'
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;Core terms and conditions
        </TextLink>
        . This offer is available until Thursday 30 November 2023. We reserve the right to amend or
        withdraw this Offer and these Offer Terms at any time. Find out more by viewing our{' '}
        <TextLink
          aria-Label={`terms and conditions`}
          href={'https://www.ovoenergy.com/terms/path-to-zero/annual-boiler-service'}
          target="_blank"
          rel="noopener noreferrer"
        >
          terms and conditions
        </TextLink>
        .
      </>
    ),
  },
};

export const beyondPromoFootnotes: Record<
  'beyondPromo' | 'beyondFreeAbs' | 'beyond12MonthsHalfPrice',
  Footnote
> = {
  beyondPromo: {
    id: 'abs-beyond-promo',
    content: (
      <>
        OVO Beyond Offer: to be eligible to get our Annual Boiler Service for £2 a month for 12
        months, you must be an OVO Beyond customer. Offer available to homeowners and new Annual
        Boiler Service customers only. Other eligibility criteria and exclusions apply see{' '}
        <TextLink
          href={'https://www.ovoenergy.com/terms/ovo-beyond/annual-boiler-service'}
          target="_blank"
          rel="noopener noreferrer"
        >
          Offer Terms
        </TextLink>{' '}
        for full details. Contract will automatically renew at the full price after 12 months unless
        cancelled. Offer is available from 1st December 2023.
      </>
    ),
  },
  beyondFreeAbs: {
    id: 'abs-beyond-promo-free-abs',
    content: (
      <>
        New customers only, not available for existing CORGI HomePlan, OVO HomePlan and OVO Energy
        boiler cover customers. Eligibility criteria, terms and exclusions apply. This plan
        automatically renews at the end of 12 months unless cancelled at the standard price for new
        sales. Offer can only be redeemed once per household. We reserve the right to amend or
        withdraw this offer at any time.
      </>
    ),
  },
  beyond12MonthsHalfPrice: {
    id: 'abs-beyond-promo-12-months-half-price',
    content: (
      <>
        OVO Beyond customers only. No claims can be made in the first 30 days. Terms, conditions,
        eligibility criteria, exclusions and cancellation charges apply. For boilers aged 7 years
        and over up to £2 a month additional premium will be charged. Offer available to new
        customers only, and not available for existing CORGI HomePlan, OVO HomePlan and OVO Energy
        boiler cover customers. This plan automatically renews at the end of 12 months unless
        cancelled at the standard price for new sales. Offer expires 23/09/2024.
      </>
    ),
  },
};

export const q1HalfPriceAbsFootnotes: Record<'q1HalfPriceAbs', Footnote> = {
  q1HalfPriceAbs: {
    id: 'q1-half-price-abs-promo',
    content: (
      <>
        Exclusive offer available with a valid promo code. New customers only, and not available for
        existing CORGI HomePlan, OVO HomePlan and OVO Energy boiler cover customers. Eligibility
        criteria, terms and exclusions apply. This plan automatically renews at prevailing headline
        price at the end of 12 months unless cancelled. We reserve the right to amend or withdraw
        this offer at any time. For full terms and conditions see{' '}
        <TextLink
          href={'https://www.ovoenergy.com/terms/ovo-beyond/annual-boiler-service'}
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </TextLink>
        .
      </>
    ),
  },
};

export const q2HalfPriceAbsFootnotes: Record<'q2HalfPriceAbs', Footnote> = {
  q2HalfPriceAbs: {
    id: 'q2-half-price-abs-promo',
    content: (
      <>
        New customers only, and not available for existing CORGI HomePlan and OVO Energy boiler
        cover customers. To be eligible for this offer you must meet the boiler service eligibility
        criteria set out during sign up. This plan automatically renews at the end of 12 months
        unless cancelled at the standard price for new sales. Offer ends on 25th July 2024
      </>
    ),
  },
};

export const q3FreeMonthsInsuranceFootnotes: Record<
  'q3FreeMonthsInsurance' | 'q3FreeMonthsInsuranceFlashSale' | 'q3FreeMonthsInsuranceBlackFriday',
  Footnote
> = {
  q3FreeMonthsInsurance: {
    id: 'q3-free-promo',
    content: (
      <>
        No claims can be made in the first 30 days. New customers only, and not available for
        existing CORGI HomePlan and OVO Energy boiler cover customers. To be eligible for this offer
        you must meet the boiler cover eligibility criteria set out during sign up. Offer expires
        12/12/2024
      </>
    ),
  },
  q3FreeMonthsInsuranceFlashSale: {
    content: (
      <>
        No claims can be made in the first 30 days. New customers only, and not available for
        existing CORGI HomePlan and OVO Energy boiler cover customers. To be eligible for this offer
        you must meet the boiler cover eligibility criteria set out during sign up. Offer expires
        17/10/2024
      </>
    ),
    id: 'q3-free-promo-flash-sale',
  },
  q3FreeMonthsInsuranceBlackFriday: {
    content: (
      <>
        Compared to the savings on the closest comparable products from our main competitors. Prices
        correct as of 19/11/2024. For verification of savings see{' '}
        <a href={blackFridayComparisonTable} target="_blank" rel="noreferrer">
          here
        </a>
        . No claims can be made in the first 30 days. New customers only, and not available for
        existing CORGI HomePlan and OVO Energy boiler cover customers. To be eligible for this offer
        you must meet the boiler cover eligibility criteria set out during sign up. Offer expires
        05/12/2024. Cancellation fees apply
      </>
    ),
    id: 'q3-free-promo-flash-sale',
  },
};

export const homeRecoverFreeRepair: Footnote = {
  id: 'home-recover-free-repair',
  content: (
    <>
      New customers only, and not available for existing CORGI HomePlan and OVO Energy boiler cover
      customers. To be eligible for this offer you must meet the boiler cover eligibility criteria
      set out during sign up. Offer expires 20/03/2025
    </>
  ),
};

export const commonFootnotesInsurance: Record<string, Footnote> = {
  tradingFca: {
    id: 'trading-fca',
    content: (
      <>
        The insurance policy is underwritten by OVO Insurance Services Ltd, a firm authorised and
        regulated by the Guernsey Financial Services Commission under reference number 2570126. OVO
        Insurance Services Ltd is registered in the Bailiwick of Guernsey under the Companies
        (Guernsey) Law 2008 (Company No. 67013). Registered office: PO Box 155, Mill Court, La
        Charroterie, St Peter Port, Guernsey, GY1 4ET.
      </>
    ),
  },
  tradingName: {
    id: 'trading-name',
    content: (
      <>
        OVO Energy is a trading name of CORGI HomePlan Ltd, a firm authorised and regulated by the
        Financial Conduct Authority under firm reference number 824122 to carry on insurance
        distribution.
      </>
    ),
  },
  tradingAddress: {
    id: 'trading-address',
    content: (
      <>
        CORGI HomePlan Ltd is registered in Scotland (Company No. SC358475). Registered Office:
        Cadworks, 41 West Campbell Street, Glasgow, G2 6SE.
      </>
    ),
  },
  tradingBusiness: {
    id: 'trading-business',
    content: <>CORGI HomePlan Ltd and OVO Insurance Services Ltd are part of OVO Group Ltd.</>,
  },
};

export const commonFootnotesNonInsurance: Record<string, Footnote> = {
  tradingAddressAbs: {
    id: 'trading-address-abs',
    content: (
      <>
        OVO Energy is a trading name of CORGI HomePlan Ltd, registered in Scotland (Company No.
        SC358475). Registered Office: Cadworks, 41 West Campbell Street, Glasgow, G2 6SE.
      </>
    ),
  },
  tradingBusinessAbs: {
    id: 'trading-business-abs',
    content: <>CORGI HomePlan Ltd is part of OVO Group Ltd.</>,
  },
};

export const q1OfferFootnotes: Record<
  | 'termsAndConditions'
  | 'termsAndConditionsPromoCode'
  | 'firstSixMonthsHalfPriceHomeRecover'
  | 'firstSixMonthsHalfPriceMaxSavingHomeRecover'
  | 'firstSixMonthsHalfPriceMaxSaving',
  Footnote
> = {
  firstSixMonthsHalfPriceMaxSaving: {
    id: 'first-six-months-half-price-max-saving',
    content: (
      <>
        The maximum potential saving is based on each plan with no excess at full price for 12
        months.
      </>
    ),
  },
  termsAndConditions: {
    id: 'terms-and-conditions',
    content: (
      <>
        No claims can be made in first 30 days. Conditions, eligibility criteria, exclusions and
        cancellation charges apply. For boilers aged 7 years and older a £1 additional premium will
        be charged for the first 6 months, increasing to £2 for the remaining 6 months. Offer
        available to new customers only, and not available for existing CORGI HomePlan, OVO HomePlan
        and OVO Energy Boiler Cover customers. Offer expires 21/03/2024
      </>
    ),
  },
  termsAndConditionsPromoCode: {
    id: 'terms-and-conditions-promo-code',
    content: (
      <>
        No claims can be made in first 30 days. Conditions, eligibility criteria, exclusions and
        cancellation charges apply. For boilers aged 7 years and older a £1 additional premium will
        be charged for the first 8 months, increasing to £2 for the remaining 4 months. Offer
        available to new customers only, and not available for existing CORGI HomePlan, OVO HomePlan
        and OVO Energy Boiler Cover customers. Offer expires 21/03/2024
      </>
    ),
  },
  firstSixMonthsHalfPriceHomeRecover: {
    id: 'first-six-months-half-price-home-recover',
    content: (
      <>
        After the initial repair, no claims can be made in first 30 days. Conditions, eligibility
        criteria, exclusions and cancellation charges apply. For boilers aged 7 years and older, a
        £1 additional premium will be charged for the first 6 months, increasing to £2 for the
        remaining 6 months. Offer available to new customers only, and not available for existing
        CORGI HomePlan, OVO HomePlan and OVO Energy Boiler Cover customers. Offer expires 21/03/24.
      </>
    ),
  },
  firstSixMonthsHalfPriceMaxSavingHomeRecover: {
    id: 'first-six-months-half-price-max-saving-home-recover',
    content: (
      <>
        The maximum potential saving of £96 above is based on the Complete plan, with no excess, at
        £32 a month for 12 months, compared to paying for 6 months. Offer expires 21/03/24.
      </>
    ),
  },
};

export const getFirstMonthsFreeTCFootnote = ({
  promoDuration,
  maxSaving,
  defaultPlanPrice,
  offerExpires,
}: {
  promoDuration: number;
  maxSaving: number;
  defaultPlanPrice?: number;
  offerExpires: string;
}) => {
  return {
    id: 'first-months-free-terms-and-conditions',
    content: (
      <>
        No claims can be made in first 30 days. Conditions, eligibility criteria, exclusions and
        cancellation charges apply. For boilers aged 7 years and older a £
        {OLDER_BOILER_PREMIUM_AMOUNT} a month premium will be added after the initial{' '}
        {promoDuration} free months. Offer available to new customers only, and not available for
        existing CORGI HomePlan, OVO HomePlan and OVO Energy Boiler Cover customers. The maximum
        potential saving of £{maxSaving} above is based on the Complete plan, with no excess, at £
        {defaultPlanPrice} a month for 12 months, compared to paying for 8 months. {offerExpires}
      </>
    ),
  };
};

export const getFirstMonthsDiscountTCFootnote = ({
  promoDuration,
  discountValue,
  offerExpires,
}: {
  promoDuration: number;
  discountValue: number;
  offerExpires: string;
}) => {
  return {
    id: 'first-months-discount-terms-and-conditions',
    content: (
      <>
        No claims can be made in first 30 days. Conditions, eligibility criteria, exclusions and
        cancellation charges apply. For boilers aged 7 years and older a £
        {OLDER_BOILER_PREMIUM_AMOUNT * (1 - discountValue / 100)} additional premium will be charged
        for the first {promoDuration} months, increasing to £2 for the remaining{' '}
        {12 - promoDuration} months. Offer available to new customers only, and not available for
        existing CORGI HomePlan, OVO HomePlan and OVO Energy Boiler Cover customers. {offerExpires}
      </>
    ),
  };
};
export const getFirstMonthsDiscountHomeRecoverTCFootnote = ({
  promoDuration,
  discountValue,
  offerExpires,
}: {
  promoDuration: number;
  discountValue: number;
  offerExpires: string;
}) => {
  return {
    id: 'first-months-discount-home-recover-terms-and-conditions',
    content: (
      <>
        After the initial repair, no claims can be made in first 30 days. Conditions, eligibility
        criteria, exclusions and cancellation charges apply. For boilers aged 7 years and older, a £
        {OLDER_BOILER_PREMIUM_AMOUNT * (1 - discountValue / 100)} additional premium will be charged
        for the first {promoDuration} months, increasing to £2 for the remaining{' '}
        {12 - promoDuration} months. Offer available to new customers only, and not available for
        existing CORGI HomePlan, OVO HomePlan and OVO Energy Boiler Cover customers. {offerExpires}
      </>
    ),
  };
};

export const getFirstMonthsDiscountMaxSavingHomeRecoverTCFootnote = ({
  maxSaving,
  defaultPlanPrice,
  promoDuration,
  offerExpires,
}: {
  promoDuration: number;
  maxSaving: number;
  defaultPlanPrice?: number;
  offerExpires: string;
}) => {
  return {
    id: 'first-months-discount-max-saving-home-recover',
    content: (
      <>
        The maximum potential saving of £{maxSaving} above is based on the Complete plan, with no
        excess, at £{defaultPlanPrice} a month for 12 months, compared to paying for {promoDuration}{' '}
        months. {offerExpires}
      </>
    ),
  };
};

export const energyRefixFreeAbsFootnote: Record<'energyRefixFreeAbs', Footnote> = {
  energyRefixFreeAbs: {
    id: 'energy-refix-free-abs',
    content: (
      <>
        New customers only, not available for existing CORGI HomePlan and OVO Energy boiler cover
        customers. This plan automatically renews at the end of 12 months unless cancelled. Offer
        can only be redeemed once per household. We reserve the right to amend or withdraw this
        offer at any time.
      </>
    ),
  },
};

export const OSPFreeAbsFootnote: Record<'OSPFreeAbs', Footnote> = {
  OSPFreeAbs: {
    id: 'osp-free-abs',
    content: (
      <>
        New customers only, not available for existing CORGI HomePlan and OVO Energy boiler cover
        customers. This plan automatically renews at the end of 12 months unless cancelled. Offer
        can only be redeemed once per household. We reserve the right to amend or withdraw this
        offer at any time.
      </>
    ),
  },
};

export const absThreeYearOnePoundOfferFootnote: Record<'absThreeYearOnePound', Footnote> = {
  absThreeYearOnePound: {
    id: 'abs-three-year-one-pound',
    content: (
      <>
        This offer is for OVO Beyond customers only. It’s not available for existing CORGI HomePlan
        and OVO home services boiler customers. To be eligible, you’ll need to meet the boiler
        service eligibility criteria set out during sign up. This plan automatically renews at the
        end of 12 months unless cancelled. Standard price from year 4. We reserve the right to amend
        or withdraw this offer at any time. For full details of OVO’s annual boiler service, see the{' '}
        <a
          href="/static/terms-abs-4d98f44d05fc44e394c55a5a2aa4dcfd.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          terms and conditions
        </a>
        .
      </>
    ),
  },
};

export const excessSignpostOffersMayVaryFootnote: Footnote = {
  id: 'excess-signpost-offers-may-vary',
  content: <>Offers may vary</>,
};

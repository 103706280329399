import { createStore } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { focusAtom } from 'jotai-optics';

import { TrackingFields } from '@services/CorgiService';
import { AutoRenewalOption, BoilerBrand, BoilerBrandExclusions, CoverType } from '@entities/enums';
import { AccountId } from '@entities/types';
import { Repair } from '@services/RepairService';
import { SignupDto } from '@src/generated/SignupDto';
import { getUrlParam } from '@utils/getUrlParam';

export interface PersonalDetails {
  title: SignupDto['customerDetails']['title'] | '';
  forename: string;
  surname: string;
  telephone: string;
  email: string;
  addressLine1: string;
  addressLine2?: string;
  postcode: string;
  town: string;
}

export interface BoilerDetails {
  isNotSupportedBoiler?: BoilerBrandExclusions;
  make: BoilerBrand | '';
  boilerAge: string;
  ownership: boolean;
  notGuesthouse: boolean;
}

export interface SensitivePaymentDetails {
  nameOnAccount: string;
  accountNumber: string;
  sortCode: string;
}

export interface PaymentDetails {
  accountOwner: boolean;
  termsAndConditions: boolean;
  rewardTermsAndConditions: boolean;
  homeRecoverTerms: boolean;
  paperless: boolean;
  autoRenewal: AutoRenewalOption;
}

export interface MarketingPermissions {
  email: boolean;
  sms: boolean;
  phone: boolean;
  post: boolean;
}

interface Tracking {
  source: string | null;
}

export interface Store {
  testUser: boolean;
  excess: number;
  accountId: AccountId | undefined;
  selectedCoverType: CoverType | undefined;
  orderReference: string | undefined;
  signupComplete: boolean;
  personalDetails: PersonalDetails;
  boilerDetails: BoilerDetails;
  paymentDetails: PaymentDetails;
  marketingPermissions: MarketingPermissions;
  sensitivePaymentDetails: SensitivePaymentDetails;
  promoCode: string | undefined;
  tracking: Tracking;
  utmTracking: TrackingFields | undefined;
  isHomeRecover: boolean;
  is95Excess: boolean;
  isAbs: boolean;
  isAgent: boolean;
  repair: Repair | undefined;
  isCreditEligible: boolean;
  creditType: 'SVT' | 'FIXED' | undefined;
  tariffName: string | undefined;
}

const defaultMarketingPermissions = {
  email: false,
  sms: false,
  phone: true,
  post: true,
};

const defaultPersonalDetails: PersonalDetails = {
  title: '',
  forename: '',
  surname: '',
  telephone: '',
  email: '',
  addressLine1: '',
  addressLine2: '',
  postcode: '',
  town: '',
};

const defaultBoilerDetails: BoilerDetails = {
  make: '',
  boilerAge: '',
  ownership: false,
  notGuesthouse: false,
  isNotSupportedBoiler: BoilerBrandExclusions.NOTEXCLUDED,
};

const defaultSensitivePaymentDetails: SensitivePaymentDetails = {
  nameOnAccount: '',
  accountNumber: '',
  sortCode: '',
};

const defaultPaymentDetails: PaymentDetails = {
  accountOwner: false,
  termsAndConditions: false,
  rewardTermsAndConditions: false,
  homeRecoverTerms: false,
  paperless: true,
  autoRenewal: AutoRenewalOption.YES,
};

const defaultTracking: Tracking = {
  source: getUrlParam('source'),
};

const sessionStorageKey = 'homePlan:store';

let storage: ReturnType<typeof createJSONStorage<Store>> | undefined;

if (typeof window !== 'undefined') {
  storage = createJSONStorage<Store>(() => sessionStorage);
}

let homePlanStore: Store | undefined;

export let store = createStore();

export const resetStore = () => {
  store = createStore();
};

if (typeof window !== 'undefined') {
  const homePlanStoreValue = sessionStorage.getItem(sessionStorageKey);
  homePlanStore = homePlanStoreValue && JSON.parse(homePlanStoreValue);
}

export const defaultStore: Store = {
  personalDetails: homePlanStore?.personalDetails || defaultPersonalDetails,
  boilerDetails: homePlanStore?.boilerDetails || defaultBoilerDetails,
  sensitivePaymentDetails: homePlanStore?.sensitivePaymentDetails || defaultSensitivePaymentDetails,
  paymentDetails: homePlanStore?.paymentDetails || defaultPaymentDetails,
  marketingPermissions: homePlanStore?.marketingPermissions || defaultMarketingPermissions,
  excess: homePlanStore?.excess ?? 60,
  signupComplete: homePlanStore?.signupComplete || false,
  promoCode: homePlanStore?.promoCode,
  tracking: homePlanStore?.tracking || defaultTracking,
  isCreditEligible: homePlanStore?.isCreditEligible || false,
  isHomeRecover: homePlanStore?.isHomeRecover || false,
  is95Excess: homePlanStore?.is95Excess || false,
  isAbs: homePlanStore?.isAbs || false,
  isAgent: homePlanStore?.isAgent || false,
  testUser: homePlanStore?.testUser || false,
  selectedCoverType: homePlanStore?.selectedCoverType,
  accountId: homePlanStore?.accountId,
  creditType: homePlanStore?.creditType,
  repair: homePlanStore?.repair,
  tariffName: homePlanStore?.tariffName,
  utmTracking: homePlanStore?.utmTracking,
  orderReference: homePlanStore?.orderReference,
};

export const homePlanStoreAtom = atomWithStorage<Store>(sessionStorageKey, defaultStore, storage);

export const personalDetailsAtom = focusAtom(homePlanStoreAtom, (optic) =>
  optic.prop('personalDetails')
);
export const boilerDetailsAtom = focusAtom(homePlanStoreAtom, (optic) =>
  optic.prop('boilerDetails')
);
export const sensitivePaymentDetailsAtom = focusAtom(homePlanStoreAtom, (optic) =>
  optic.prop('sensitivePaymentDetails')
);
export const paymentDetailsAtom = focusAtom(homePlanStoreAtom, (optic) =>
  optic.prop('paymentDetails')
);
export const marketingPermissionsAtom = focusAtom(homePlanStoreAtom, (optic) =>
  optic.prop('marketingPermissions')
);
export const excessAtom = focusAtom(homePlanStoreAtom, (optic) => optic.prop('excess'));
export const signupCompleteAtom = focusAtom(homePlanStoreAtom, (optic) =>
  optic.prop('signupComplete')
);
export const promoCodeAtom = focusAtom(homePlanStoreAtom, (optic) => optic.prop('promoCode'));
export const trackingAtom = focusAtom(homePlanStoreAtom, (optic) => optic.prop('tracking'));
export const isCreditEligibleAtom = focusAtom(homePlanStoreAtom, (optic) =>
  optic.prop('isCreditEligible')
);
export const isHomeRecoverAtom = focusAtom(homePlanStoreAtom, (optic) =>
  optic.prop('isHomeRecover')
);
export const is95ExcessAtom = focusAtom(homePlanStoreAtom, (optic) => optic.prop('is95Excess'));
export const isAbsAtom = focusAtom(homePlanStoreAtom, (optic) => optic.prop('isAbs'));
export const isAgentAtom = focusAtom(homePlanStoreAtom, (optic) => optic.prop('isAgent'));
export const testUserAtom = focusAtom(homePlanStoreAtom, (optic) => optic.prop('testUser'));
export const repairAtom = focusAtom(homePlanStoreAtom, (optic) => optic.prop('repair'));

export const selectedCoverTypeAtom = focusAtom(homePlanStoreAtom, (optic) =>
  optic.prop('selectedCoverType')
);
export const orderReferenceAtom = focusAtom(homePlanStoreAtom, (optic) =>
  optic.prop('orderReference')
);
export const utmTrackingAtom = focusAtom(homePlanStoreAtom, (optic) => optic.prop('utmTracking'));
export const tariffNameAtom = focusAtom(homePlanStoreAtom, (optic) => optic.prop('tariffName'));
export const creditTypeAtom = focusAtom(homePlanStoreAtom, (optic) => optic.prop('creditType'));
